import {useHistory} from "react-router-dom";
import {SUB_TYPES} from "../../../../Constants/Constants";
import Pages, {PageUrl} from "../../../../Constants/Pages";
import Store from "../../../../store/store";
import store from "../../../../store/store";
import NombaseHelmet from "../../../../Components/Nombase/NombaseHelmet";
import NombaseRegisterWrapper from "../../../../Components/Nombase/NombaseRegisterWrapper/NombaseRegisterWrapper";
import NombaseRegisterBox from "../../../../Components/Nombase/NombaseRegisterBox/NombaseRegisterBox";
import NombaseRegisterButtons from "../../../../Components/Nombase/NombaseRegisterButtons/NombaseRegisterButtons";
import NombaseButton from "../../../../Components/Nombase/NombaseButton/NombaseButton";
import NombaseReturnToLoginButton from "../../../../Components/Nombase/NombaseReturnToLoginButton/NombaseReturnToLoginButton";

const NombaseRegister = () => {
    const history = useHistory();

    return (
        <>
            <NombaseHelmet title="Register" />
            <NombaseRegisterWrapper>
                <NombaseRegisterBox
                    title="Register"
                    description={
                        <>
                            Creating a free BevNET account provides you limited access
                            to Nombase as well as certain areas of BevNET, Nosh,
                            and Brewbound. Want full access? Become a BevNET &
                            Nosh or Brewbound Insider, or subscribe to our All
                            Access plan to stay informed on the entire industry.
                        </>
                    }
                >
                    <NombaseRegisterButtons>
                        <NombaseButton
                            buttonStyle="outline"
                            fullWidth
                            onClick={() => {
                                history.push(
                                    PageUrl(
                                        Pages.REGISTER_FREE,
                                        Store.returnUrl
                                    )
                                );
                            }}
                            cypressData="freeRegistration"
                        >
                            Free Registration (Limited Access)
                        </NombaseButton>
                        <NombaseButton
                            fullWidth
                            onClick={() => {
                                store.hostSubTypes === SUB_TYPES.BEVNET_NOSH
                                    ? (window.location.href =
                                          "https://app.bevnet.com/subscribe")
                                    : (window.location.href =
                                          "https://app.bevnet.com/subscribe/brewbound");
                            }}
                        >
                            Become a BevNET & Nosh Insider
                        </NombaseButton>
                    </NombaseRegisterButtons>
                    <NombaseReturnToLoginButton />
                </NombaseRegisterBox>
            </NombaseRegisterWrapper>
        </>
    );
};

export default NombaseRegister;
